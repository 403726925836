import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function getPayments(paymentTypeID, partnerID, periodID) {
    return http.get(replaceRouteParams(API_PATHS.getMemberPayments, paymentTypeID, partnerID, periodID));
};

export function checkMemberPaymentsProcessed(partnerId, periodId) {
    return http.get(replaceRouteParams(API_PATHS.checkMemberPaymentsProcessed, partnerId, periodId));
};

export function sendMemberPayments(partnerId, periodId, pin) {
    return http.post(replaceRouteParams(API_PATHS.sendMemberPayments, partnerId, periodId), { pin });
};