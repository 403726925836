import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';

export function getGroupMembership(partnerId) {
    return http.get(API_PATHS.getGroupMembership, {
        params: { partnerId: partnerId ? partnerId : null }
    });
}

export function updateGroupMembership(groupMembership)
{
    return http.put(API_PATHS.updateGroupMembership, groupMembership);
}

export function addGroupMembership(groupMembership)
{
    return http.post(API_PATHS.addGroupMembership, groupMembership);
}