import React, { useState, forwardRef, useRef, useMemo } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import './_search-box.scss';
import ToolTip from '../controls/ToolTip';
import { Badge } from '@material-ui/core';
import IconButton from '../../components/IconButton/IconButton';

const SearchBox = forwardRef(function SearchBox(props, externalRef) {
    const { placeholder, onChange = () => { }, className, showFilterIcon, onShowFilter, filterTooltip, hasFilterSelection, searchInputId, listBoxId, ariaHasPopup, ariaAutocomplete, filterExpandContentId, showFilterPanel, activeDescendant, isExpanded, searchResultsCount } = props;
    const [clearIconVisible, setClearIconVisible] = useState(false);
    const ref = externalRef || useRef(null);
    const emptyString = '';
    const onChangeDebounced = useMemo(() => debounce(onChange, 400), []);
    const searchInputLabelId = `label_${searchInputId}`;
    const searchboxInstructionsId = 'searchboxInstructionsId';
    const [isInstructionsHidden, setIsInstructionsHidden] = useState(true);

    const changeHandler = (e) => {
        const hasValue = !!e.target.value;
        if (hasValue) {
            setClearIconVisible(true);
        }
        onChangeDebounced(e.target.value);
    };

    const focusHandler = () => {
        setIsInstructionsHidden(false);
    };

    const blurHandler = () => {
        setIsInstructionsHidden(true);
    };

    const clearButtonClickHandler = () => {
        if (clearIconVisible) {
            ref.current.value = emptyString;
            onChangeDebounced(emptyString);
            ref.current.focus();
            setClearIconVisible(false);
        }
    };

    return <div className={`search-box ${className}`}>
        <span className="search-box__search-icon-container">
            <MaterialIcon className="search-box__search-icon" icon="search" />
        </span>
        <label id={searchInputLabelId} className="sr-only" htmlFor={searchInputId}>Search for gyms</label>
        <input className="search-box__search-input ellipsis"
            id={searchInputId}
            placeholder={placeholder}
            type="text"
            role="combobox" //the role is needed for the aria-expanded
            aria-activedescendant={activeDescendant}
            ref={ref}
            onChange={changeHandler}
            aria-controls={listBoxId}
            aria-haspopup={ariaHasPopup}
            aria-autocomplete={ariaAutocomplete}
            aria-labelledby={searchInputLabelId}
            aria-describedby={searchboxInstructionsId}
            aria-expanded={isExpanded}
            onFocus={focusHandler}
            onBlur={blurHandler}
        />
        <div id={searchboxInstructionsId} className="sr-only" aria-hidden={isInstructionsHidden ? 'true' : undefined}>Begin typing to search, use arrow keys to navigate, Enter to select</div>
        <div className="search-box__results-count sr-only" aria-live="assertive">{`${searchResultsCount} results found`}</div>
        {clearIconVisible &&
            <IconButton icon={'clear'}
                role="button"
                tabIndex="0"
                iconClassName="search-box__clear-icon"
                clickHandler={clearButtonClickHandler}
            />
        }
        {showFilterIcon
            && <ToolTip title={filterTooltip} arrow placement="top">
                <Badge variant="dot" invisible={!hasFilterSelection}>
                    <div className="search-box__filter-container">
                        <IconButton icon={'tune'}
                            role="button"
                            tabIndex="0"
                            iconClassName="search-box__filter-icon"
                            clickHandler={onShowFilter}
                            filterExpandContentId={filterExpandContentId}
                            ariaExpanded={showFilterPanel}
                        />
                    </div>
                </Badge>
            </ToolTip>}
    </div>;
});

SearchBox.defaultProps = {
    filterTooltip: 'Filter'
};

SearchBox.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    showFilterIcon: PropTypes.bool,
    onShowFilter: PropTypes.func,
    filterTooltip: PropTypes.string,
    searchInputId: PropTypes.string,
    listBoxId: PropTypes.string,
    ariaHasPopup: PropTypes.string,
    ariaAutocomplete: PropTypes.string,
    filterExpandContentId: PropTypes.string,
    showFilterPanel: PropTypes.bool,
    activeDescendant: PropTypes.string,
    isExpanded: PropTypes.bool,
    searchResultsCount: PropTypes.number
};

export default SearchBox;