import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import selectors from './visitLogTabSelectors';
import visitLogResources from './visitLogTabResources';
import { getClosedUsagePeriods } from '../../Shared/Periods/periodsActions';
import actions from './visitLogTabActions';
import { isTabletSize } from '../../../utilities/windowSize';
import NewTable from '../../../components/NewInfoBox/NewTable';
import Button from '../../../components/controls/Button';
import MaterialIcon from '../../../components/MaterialIcon/MaterialIcon';
import BulletCell from '../../../components/NewInfoBox/BulletCell';
import { invitationStatusesColorsMap } from '../../../enums/invitationStatuses';
import MuiInputSelect from '../../../components/controls/MuiInputSelect';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import { defaultFormProperties } from '../../../utilities/form';
function VisitLogTab({
    isLoading,
    periods,
    getClosedUsagePeriods,
    selectedPeriod,
    getVisits,
    visits,
    partnerID,
    defaultPeriod,
    downloadVisits }) {
    const isTablet = isTabletSize();
    useEffect(() => {
        getClosedUsagePeriods();
    }, []);
    useEffect(() => {
        if (partnerID && selectedPeriod > 0) {
            getVisits(partnerID, selectedPeriod);
        }
    }, [selectedPeriod]);
    const exportToExcel = () => {
        if (partnerID && selectedPeriod > 0) {
            downloadVisits(partnerID, selectedPeriod);
        }
    };
    const columns = useMemo(
        () => [
            {
                Header: visitLogResources.labelEmployeeName,
                accessor: (x) => {
                    return x.memberFirstName ? `${x.memberFirstName} ${x.memberLastName}` : '';
                },
                sticky: isTablet ? 'left' : null,
                left: isTablet ? 0 : null
            },
            {
                Header: visitLogResources.labelStatus,
                accessor: 'invitationStatusDescription',
                Cell: BulletCell,
                getProps: row => ({
                    theme: invitationStatusesColorsMap[row.original.invitationStatus]
                })
            },
            {
                Header: visitLogResources.labelEmail,
                accessor: 'memberEmail',
                minWidth: 200
            },
            {
                Header: visitLogResources.labelFacilityID,
                accessor: 'facilityIDNumber'
            },
            {
                Header: visitLogResources.labelFaciityName,
                accessor: 'facilityName',
                minWidth: 200
            },
            {
                Header: visitLogResources.labelVisits,
                accessor: 'usagesCount',
                maxWidth: 50
            }
        ],
        [visitLogResources]
    );
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <>
                <div className="grid__row align-items-center">
                    <div className="col col-lg-3 col-md-6">
                        {defaultPeriod.get(visitLogResources.fieldPeriod) > 0
                            ? <FormGroup name={visitLogResources.formVisitMonth} initialData={defaultPeriod}>
                                <Field
                                    component={MuiInputSelect}
                                    id={visitLogResources.fieldPeriod}
                                    name={visitLogResources.fieldPeriod}
                                    label={visitLogResources.labelVisitMonth}
                                    options={periods}
                                    getOptionLabel={option => (option ? option.get('yearMonth') : '')}
                                    getOptionValue={option => (option ? option.get('id') : '')}
                                />
                            </FormGroup>
                            : null}
                    </div>
                    <div className="col col-lg-9 col-md-6">
                        <Button className="btn_new btn_new--white float-right"
                            onClick={exportToExcel} disabled={!selectedPeriod}
                        >
                            <MaterialIcon className="btn_new-icon" icon="download" />
                        </Button>
                    </div>
                </div>
                <NewTable columns={columns} initialData={visits}
                    totalCount={visits.size}
                    getItemId={item => item.facilityMemberID}
                    initialSortBy={{
                        id: 'memberFirstName',
                        desc: false
                    }}
                />
            </>
        );
}

VisitLogTab.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    periods: PropTypes.object.isRequired,
    getClosedUsagePeriods: PropTypes.func.isRequired,
    selectedPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setPeriod: PropTypes.func.isRequired,
    getVisits: PropTypes.func.isRequired,
    visits: PropTypes.object.isRequired,
    partnerID: PropTypes.number,
    defaultPeriod: PropTypes.object,
    downloadVisits: PropTypes.func.isRequired
};
const mapDispatchToProps = {
    getClosedUsagePeriods,
    ...actions
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default reduxForm({
    form: visitLogResources.formVisitMonth,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    ...defaultFormProperties
})(connectStateToProps(VisitLogTab));