import { createSelector } from 'reselect';
import {
    CREATE_KEYFOBS,
    UPLOAD_MEMBER_RECIPIENTS,
    GET_KEYFOBS_LIST,
    DEACTIVATE_MEMBERSHIP,
    REASSIGN_KEYFOB,
    GET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED,
    GET_PROGRAM_PAYMENT_TYPE_ID,
    DELETE_MEMBERSHIP
} from './employeeManagementTypes';
import { GET_PARTNER } from '../../Shared/Partners/managePartnerTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === CREATE_KEYFOBS || i === UPLOAD_MEMBER_RECIPIENTS || i === GET_KEYFOBS_LIST
            || DEACTIVATE_MEMBERSHIP || REASSIGN_KEYFOB || i === GET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED || i === GET_PROGRAM_PAYMENT_TYPE_ID || i === DELETE_MEMBERSHIP
    ).size;
    const notSize = state.loadingReducer.get('loadings').filter(
        i => i === GET_PARTNER
    ).size;
    return size > 0 && notSize === 0;
};
const getPartner = state => state.managePartnerReducer.get('partner');
const getNumberOfMembership = state => state.groupMembershipsReducer.getIn(['groupMembership', 'numberOfMemberships']);
const getEmployeesList = state => state.employeeManagementReducer.get('keyfobs').toJS();

export default createSelector(
    [getIsLoading, getPartner, getNumberOfMembership, getEmployeesList],
    (isLoading, partner, numberOfMembership, employeesList) => ({
        isLoading,
        partner,
        numberOfMembership,
        employeesList
    })
);