import * as types from './groupMembershipsTypes';
import initialState from './groupMembershipInitialState';
import { fromJS } from 'immutable';

export default function groupMembershipsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GROUP_MEMBERSHIP:
            return state.set('groupMembership', fromJS(action.groupMembership));
        case types.SET_GROUP_MEMBERSHIP_TO_ADD:
            return state.set('groupMembershipToAdd', action.groupMembershipToAdd);
        default:
            return state;
    }
}