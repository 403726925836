import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import selectors from './partnerManagerEmployeeManagementSelectors';
import KeyfobsAndEmployeesList from './KeyfobsAndEmployeesList/KeyfobsAndEmployeesList';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Spinner from '../../../components/Spinner/Spinner';
import CardInfo from '../../../components/CardInfo/CardInfo';
import UploadMemberRecipientsForm from './UploadMemberRecipients/UploadMemberRecipientsForm/UploadMemberRecipientsForm';

function PartnerManagerEmployeeManagementTab({ partnerId, isLoading }) {
    const { gmrAddProgram } = useFlags();
    const employeesListRef = useRef();
    return <CardInfo shadow={false} halfSpace>
        {isLoading ? <Spinner /> : null}
        {gmrAddProgram &&
            <UploadMemberRecipientsForm partnerId={partnerId} />
        }
        <KeyfobsAndEmployeesList partnerId={partnerId} ref={employeesListRef} />
    </CardInfo>;
}

PartnerManagerEmployeeManagementTab.propTypes = {
    partnerId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
const connectStateToProps = connect(selectors);
export default connectStateToProps(PartnerManagerEmployeeManagementTab);