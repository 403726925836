import {
    GET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER,
    SET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER
} from './partnerManagerAreaTypes';
import * as loadingActions from '../Loading/loadingActions';
import partnerManagerAreaService from './partnerManagerAreaService';

const setIsGroupMembershipAddedToPartner = isGroupMembershipAddedToPartner => ({
    type: SET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER,
    payload: {
        isGroupMembershipAddedToPartner
    }
});

export const getIsGroupMembershipAddedToPartner = partnerId => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(GET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER));
    partnerManagerAreaService.getIsGroupMembershipAddedToPartner(partnerId)
        .then((response) => {
            dispatch(setIsGroupMembershipAddedToPartner(response.data));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(GET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER));
        });
};