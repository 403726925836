import { createSelector } from 'reselect';
import { GET_GROUP_MEMBERSHIP, UPDATE_GROUP_MEMBERSHIP, ADD_GROUP_MEMBERSHIP } from './groupMembershipsTypes';
import { GetAddingConfirmationMessage } from './GroupMembershipsInfoForm/groupMembershipsInfoResources';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(
            i =>
                i === GET_GROUP_MEMBERSHIP || i === UPDATE_GROUP_MEMBERSHIP || i === ADD_GROUP_MEMBERSHIP
        ).size;

    return size > 0;
};
const getIsGroupMembershipAddedToPartner = state => state.partnerOwnerAreaReducer.get('isGroupMembershipAddedToPartner');
const getGroupMembership = state => state.groupMembershipsReducer.get('groupMembership');
const getGroupMembershipToAdd = state => state.groupMembershipsReducer.get('groupMembershipToAdd');
const getIsExisting = state => state.groupMembershipsReducer.getIn(['groupMembership', 'id']) > 0;

const getIsConfirmationPopupVisible = createSelector(
    [getGroupMembershipToAdd],
    (groupMembershipToAdd) => {
        return groupMembershipToAdd !== undefined;
    }
);
const getSavingConfirmationPopupMessage = createSelector(
    [getGroupMembershipToAdd],
    (groupMembershipToAdd) => {
        if (groupMembershipToAdd === undefined) {
            return;
        }
        return GetAddingConfirmationMessage(groupMembershipToAdd.get('programPaymentTypeID'));
    }
);
export default createSelector(
    [
        getIsLoading,
        getGroupMembership,
        getIsConfirmationPopupVisible,
        getGroupMembershipToAdd,
        getSavingConfirmationPopupMessage,
        getIsGroupMembershipAddedToPartner,
        getIsExisting
    ],
    (
        isLoading,
        groupMembership,
        isConfirmationPopupVisible,
        groupMembershipToAdd,
        savingConfirmationPopupMessage,
        isGroupMembershipAddedToPartner,
        isExisting
    ) => ({
        isLoading,
        groupMembership,
        isConfirmationPopupVisible,
        groupMembershipToAdd,
        savingConfirmationPopupMessage,
        isGroupMembershipAddedToPartner,
        isExisting
    })
);
