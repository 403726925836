const MemberResources = {
    labelActions: 'Actions',
    labelFacilityMemberID: 'Key fob/Facility Member ID',
    labelEmployeeName: 'Employee Name',
    labelDateOfBirth: 'Date of Birth',
    labelPlans: 'Plans',
    labelFacilityName: 'Facility Name',
    labelFacilityID: 'Facility ID',
    facilitySearchBoxInputLabel: 'Facilities',
    title: 'Members',
    defaultSortColumn: 'memberFirstName',
    placeholderMemberSearch: 'Search by member or key fob',
    accessorFacilityMemberID: 'facilityMemberID',
    accessorEmployeeName: 'memberFirstName',
    accessorMemberDateOfBirth: 'memberDateOfBirth',
    accessorFacilityName: 'facilityName',
    accessorFacilityIDNumber: 'facilityIDNumber',
    accessorStatus: 'memberStatus',
    accessorPlanName: 'planName',
    accessorMemberStatus: 'memberStatus',
    textNoMemberFound: 'Member wasn’t found',
    bulkOptionsButtonText: 'BULK OPTIONS',
    buttonDeactivate: 'Deactivate',
    tooltipDeactivate: 'Deactivate key fob',
};

export function GetDeactivationConfirmationMessage(memberFirstName, memberLastName, facilityMemberID)
{
    return `Are you sure you want to deactivate ${memberFirstName} ${memberLastName} with keyfob ${facilityMemberID}?`;
};

export default MemberResources;
