import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './uploadMemberRecipientsForm.scss';
import uploadMemberRecipientsResources from '../uploadMemberRecipientsResources';
import resources from '../../../../Shared/resources';
import ConfirmPopup from '../../../../../components/Popups/ConfirmAlert/ConfirmPopup';
import MuiInputFile from '../../../../../components/controls/MuiInputFile';
import { uploadMemberRecipients, showFileUploadErrorAlert } from '../../employeeManagementActions';
import { setUploadResults, setUploadResultsFileName } from '../../../../Shared/UploadRecipientsResults/uploadRecipientsResultsActions';

const UploadMemberRecipientsForm = ({
    partnerId,
    uploadMemberRecipients,
    setUploadResults,
    showFileUploadErrorAlert
}) => {
    const [goodRecords, setGoodRecords] = useState([]);
    const [badRecords, setBadRecords] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [fileName, setFileName] = useState('');
    const history = useHistory();
    const fileSize = 25600;
    const handleFileUpload = (event) => {
        setGoodRecords([]);
        setBadRecords([]);
        const file = event.target.files[0];
        if (!file) {
            event.target.value = '';
            return;
        }
        if (file.size > fileSize) {
            showFileUploadErrorAlert(uploadMemberRecipientsResources.fileSizeError);
            event.target.value = '';
            return;
        }
        setFileName(file.name);
        uploadMemberRecipients(partnerId, file)
            .then((response) => {
                const good = [];
                const bad = [];
                response.data.forEach((item) => {
                    if (item.reason === null || item.reason === '') {
                        good.push(item);
                    } else {
                        bad.push(item);
                    }
                }
                );
                setGoodRecords(good);
                setBadRecords(bad);
                setShowConfirmation(true);
            })
            .catch(() => {
                setShowConfirmation(false);
            });
        event.target.value = '';
    };

    const handleViewDetails = () => {
        setUploadResultsFileName(fileName);
        setUploadResults({ goodRecords, badRecords });
        setShowConfirmation(false);
        history.push('/uploadresults');
    };

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
    };

    const totalRecords = () => goodRecords.length + badRecords.length;

    return (
        <>
            <ConfirmPopup showPopup={showConfirmation} body={
                <>
                    <h3 className="upload-member-recipients__title">{uploadMemberRecipientsResources.resultsPopupTitle}</h3>
                    <h4 className="upload-member-recipients__subtitle">{fileName}</h4>
                    <div className="popup-confirmation-panel">
                        <div className="col col-4 processed">{uploadMemberRecipientsResources.resultsProcessed}: {totalRecords()}</div>
                        <div className="col col-4 uploaded">{uploadMemberRecipientsResources.resultsUploaded}: {goodRecords.length}</div>
                        <div className="col col-4 failed">{uploadMemberRecipientsResources.resultsFailed}: {badRecords.length}</div>
                    </div>
                </>
            }
                onConfirm={badRecords && badRecords.length === 0 ? null : handleViewDetails}
                onCancel={handleCloseConfirmation}
                labelOk={uploadMemberRecipientsResources.buttonViewDetails}
                labelCancel={resources.closeButtonText}
                buttonSize="large"
            />
            <div className="grid__row">
                <div className="col-12 upload_container">
                    <div>
                        <h3 className="upload-member-recipients__title">{uploadMemberRecipientsResources.uploadSectionTitle}</h3>
                        <label className="upload-member-recipients__subtitle-label">{uploadMemberRecipientsResources.uploadSectionDescription}</label>
                    </div>
                    <MuiInputFile
                        buttonText={resources.uploadButtonText}
                        onChange={handleFileUpload}
                        showFileName={false}
                        accept={uploadMemberRecipientsResources.extensionAccepted}
                        size="small"
                        maxFileSize={fileSize}
                    />
                </div>
            </div>
        </>
    );
};

UploadMemberRecipientsForm.propTypes = {
    partnerId: PropTypes.number.isRequired,
    uploadMemberRecipients: PropTypes.func.isRequired,
    setUploadResults: PropTypes.func.isRequired,
    showFileUploadErrorAlert: PropTypes.func.isRequired
};
const mapDispatchToProps = {
    uploadMemberRecipients,
    setUploadResults,
    showFileUploadErrorAlert
};

const connectStateToProps = connect(null, mapDispatchToProps);
export default connectStateToProps(UploadMemberRecipientsForm);
