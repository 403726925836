import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Spinner from '../../../components/Spinner/Spinner';
import PatnerPaymentsGenerationForm from '../PartnerPaymentsGenerationForm/PatnerPaymentsGenerationForm';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import actions from './partnerPaymentsGenerationSectionActions';
import selectors from './partnerPaymentsGenerationSectionSelectors';
import ConfirmPopup from '../../../components/Popups/ConfirmAlert/ConfirmPopup';
import ConnectedConfirmPopup from '../../../components/Popups/ConfirmAlert/ConnectedConfirmPopup';
import PartnerPaymentsFeesConfirmationForm from '../PartnerPaymentsFeesConfirmationForm/partnerPaymentsFeesConfirmationForm';
import { submit } from 'redux-form';
import { getClosedUsagePeriods, getPeriodsUntilCurrent } from '../../Shared/Periods/periodsActions';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';
import paymentTypesEnum from '../../../enums/paymentTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PartnerPaymentsGenerationSection = ({
    isLoading,
    programTypes,
    programPaymentTypes,
    partners,
    periods,
    paymentsGenerationSettings,
    setProgramType,
    setProgramPaymentType,
    setPartner,
    setPeriod,
    generate,
    checkPaymentsExistence,
    doPaymentsExist,
    message,
    selectedPeriodID,
    selectedPartnerID,
    selectedProgramPaymentTypeID,
    selectedProgramTypeID,
    resetPaymentsExistenceCheck,
    getPartnersListByProgramPaymentType,
    setIsGenerationWithFees,
    isGenerationWithFees,
    paymentsFees,
    confirmationMessage,
    setIsAdditionalFeesPopupVisible,
    isAdditionalFeesPopupVisible,
    getClosedUsagePeriods,
    getPeriodsUntilCurrent,
    defaultProgramType,
    getPartnersListByProgramType,
    defaultProgramPaymentType,
    paymentTypes,
    getPaymentTypesByProgramPaymentType,
    setPaymentType,
    selectedPaymentTypeID,
    defaultPaymentType
}) => {
    const { gmrAddProgram } = useFlags();
    useEffect(() => {
        if (!gmrAddProgram) {
            setProgramType(defaultProgramType);
            if (defaultProgramType) {
                getPartnersListByProgramType(defaultProgramType.get('id'));
            }
            getClosedUsagePeriods();
        }
        else {
            setProgramPaymentType(defaultProgramPaymentType);
            if (defaultProgramPaymentType && defaultPaymentType) {
                programPaymentTypeChangeHandler(defaultProgramPaymentType.get('id'));
            }
        }
    }, []);
    useEffect(() => {
        if (gmrAddProgram && selectedProgramPaymentTypeID && selectedPeriodID && selectedPartnerID && selectedPaymentTypeID) {
            checkPaymentsExistence(0, selectedProgramPaymentTypeID, selectedPeriodID, selectedPartnerID, selectedPaymentTypeID);
        }
        else if (!gmrAddProgram && selectedProgramTypeID && selectedPeriodID && selectedPartnerID) {
            checkPaymentsExistence(selectedProgramTypeID, 0, selectedPeriodID, selectedPartnerID, paymentTypesEnum.gmPartnerFees);
        }
    }, [selectedProgramTypeID, selectedProgramPaymentTypeID, selectedPeriodID, selectedPartnerID, selectedPaymentTypeID]);
    useEffect(() => {
        if (doPaymentsExist === false) {
            confirmGenerate();
        }
    }, [doPaymentsExist]);
    const handleGenerate = (settings) => {
        const programPaymentType = settings.get('programPaymentType');
        const programType = settings.get('programType');
        const partner = settings.get('partner');
        const period = settings.get('period');
        const paymentType = settings.get('paymentType');
        setProgramType(programType);
        setProgramPaymentType(programPaymentType);
        setPartner(partner);
        setPeriod(period);
        setPaymentType(paymentType);
    };
    const handleGenerateWithFees = (settings) => {
        handleGenerate(settings);
        setIsGenerationWithFees(true);
    };
    const confirmGenerate = () => {
        if (isGenerationWithFees) {
            resetPaymentsExistenceCheck();
            setIsAdditionalFeesPopupVisible(true);
            return;
        }
        closePopup();
        const generateParams = createGenerateParams();
        generate(generateParams);
    };
    const confirmGenerateWithFees = (formObj) => {
        closePopup();
        const generateParams = createGenerateParams();
        generateParams.startupFee = formObj.get('startupFeeAmount');
        generateParams.renewalFee = formObj.get('renewalFeeAmount');
        generateParams.facilityAccessFee = formObj.get('facilityAccessFeeAmount');
        generateParams.oneTimeFee = formObj.get('oneTimeFeeAmount');
        generateParams.oneTimeFeeReason = formObj.get('oneTimeFeeReason');
        generate(generateParams);
    };
    const closePopup = () => {
        resetPaymentsExistenceCheck();
        setPartner();
        setPeriod();
        setPaymentType(defaultPaymentType);
        setIsGenerationWithFees(false);
        setIsAdditionalFeesPopupVisible(false);
    };
    const createGenerateParams = () => {
        return {
            usagePeriodID: selectedPeriodID,
            programTypeID: selectedProgramTypeID,
            programPaymentTypeID: selectedProgramPaymentTypeID,
            partnerID: selectedPartnerID,
            paymentTypeID: selectedPaymentTypeID
        };
    };
    const programPaymentTypeChangeHandler = (programPaymentTypeID) => {
        getPartnersListByProgramPaymentType(programPaymentTypeID, null);
        getPaymentTypesByProgramPaymentType(programPaymentTypeID);
        paymentTypeChangeHandler(programPaymentTypeID, defaultPaymentType.get('id'));
        setPaymentType(defaultPaymentType);
        setPartner();
        setProgramType();
    };
    const paymentTypeChangeHandler = (programPaymentTypeID, paymentTypeID) => {
        if (paymentTypeID === paymentTypesEnum.gmPartnerFees
            && (programPaymentTypeID === programPaymentTypesEnum.memberPaid
                || programPaymentTypeID === programPaymentTypesEnum.partnerMemberPaid)) {
            getPeriodsUntilCurrent();
        }
        else if (programPaymentTypeID === programPaymentTypesEnum.partnerPaid
            || (programPaymentTypeID === programPaymentTypesEnum.partnerMemberPaid && paymentTypeID === paymentTypesEnum.gmPartnerDues)
            || (programPaymentTypeID === programPaymentTypesEnum.memberPaid && paymentTypeID === paymentTypesEnum.gmMemberReimbursements)) {
            getClosedUsagePeriods();
        }
        getPartnersListByProgramPaymentType(programPaymentTypeID, paymentTypeID);
        setPeriod();
    };
    const partnerPaymentsFeesConfirmationForm = 'partnerPaymentsFeesConfirmationForm';
    return <InfoBox title={wellnessProgramPaymentResources.titlePartnerPayments} bordered>
        {
            isLoading
                ? <Spinner />
                : <></>
        }
        <ConfirmPopup showPopup={doPaymentsExist || false} body={<div className="message">{message}</div>}
            onConfirm={confirmGenerate}
            onCancel={closePopup}
        />
        <ConnectedConfirmPopup
            showPopup={isAdditionalFeesPopupVisible}
            title={confirmationMessage}
            body={
                <PartnerPaymentsFeesConfirmationForm
                    form={partnerPaymentsFeesConfirmationForm}
                    onSubmit={confirmGenerateWithFees}
                    initialValues={paymentsFees}
                />}
            onConfirm={(dispatch) => {
                dispatch(submit(partnerPaymentsFeesConfirmationForm));
            }}
            onCancel={closePopup}
        />
        <PatnerPaymentsGenerationForm
            gmrAddProgram={gmrAddProgram}
            programTypes={programTypes}
            programPaymentTypes={programPaymentTypes}
            partners={partners}
            periods={periods}
            initialValues={paymentsGenerationSettings}
            paymentTypes={paymentTypes}
            onSubmitGenerateWithFees={handleGenerateWithFees}
            onSubmitGenerate={handleGenerate}
            onProgramPaymentTypeChange={programPaymentTypeChangeHandler}
            onPaymentTypeChange={paymentTypeChangeHandler}
        />
    </InfoBox>;
};

PartnerPaymentsGenerationSection.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    programTypes: PropTypes.object.isRequired,
    defaultProgramType: PropTypes.object,
    programPaymentTypes: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    periods: PropTypes.object,
    paymentsGenerationSettings: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    setProgramType: PropTypes.func.isRequired,
    setProgramPaymentType: PropTypes.func.isRequired,
    setPartner: PropTypes.func.isRequired,
    setPeriod: PropTypes.func.isRequired,
    generate: PropTypes.func.isRequired,
    checkPaymentsExistence: PropTypes.func.isRequired,
    resetPaymentsExistenceCheck: PropTypes.func.isRequired,
    doPaymentsExist: PropTypes.bool,
    selectedProgramTypeID: PropTypes.number,
    selectedProgramPaymentTypeID: PropTypes.number,
    selectedPartnerID: PropTypes.number,
    selectedPeriodID: PropTypes.number,
    getPartnersListByProgramPaymentType: PropTypes.func.isRequired,
    isGenerationWithFees: PropTypes.bool,
    setIsGenerationWithFees: PropTypes.func.isRequired,
    paymentsFees: PropTypes.object.isRequired,
    confirmationMessage: PropTypes.string.isRequired,
    isAdditionalFeesPopupVisible: PropTypes.bool.isRequired,
    setIsAdditionalFeesPopupVisible: PropTypes.func.isRequired,
    getClosedUsagePeriods: PropTypes.func.isRequired,
    getPeriodsUntilCurrent: PropTypes.func.isRequired,
    getPartnersListByProgramType: PropTypes.func.isRequired,
    defaultProgramPaymentType: PropTypes.object,
    paymentTypes: PropTypes.object,
    getPaymentTypesByProgramPaymentType: PropTypes.func.isRequired,
    setPaymentType: PropTypes.func.isRequired,
    selectedPaymentTypeID: PropTypes.number,
    defaultPaymentType: PropTypes.object
};

const mapDispatchToProps = {
    getClosedUsagePeriods,
    getPeriodsUntilCurrent,
    ...actions
};

export default connect(selectors, mapDispatchToProps)(PartnerPaymentsGenerationSection);