import { createSelector } from 'reselect';
import { GET_VISITS, DOWNLOAD_VISITS } from './visitLogTabTypes';
import { GET_CLOSED_USAGE_PERIODS } from '../../Shared/Periods/periodsTypes';
import { formValueSelector } from 'redux-form';
import visitLogResources from './visitLogTabResources';
import { fromJS } from 'immutable';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === GET_VISITS || i === GET_CLOSED_USAGE_PERIODS || i === DOWNLOAD_VISITS
    ).size;
    return size > 0;
};

const getPeriods = state => state.periodsReducer.get('closedUsagePeriods');
const getVisits = state => state.visitLogTabReducer.get('visits');
const getDefaultPeriod = createSelector([getPeriods], (periods) => {
    return periods.first() || fromJS({ id : '' });
});

const formSelector = formValueSelector(visitLogResources.formVisitMonth, state => state.form.toJS());
const getSelectedPeriod = state => (
    formSelector
        ? formSelector(state, visitLogResources.fieldPeriod)
        : getDefaultPeriod()
            .get(visitLogResources.fieldPeriod));

export default createSelector(
    [getIsLoading, getPeriods, getSelectedPeriod, getVisits, getDefaultPeriod],
    (isLoading, periods, selectedPeriod, visits, defaultPeriod) => ({
        isLoading,
        periods,
        selectedPeriod,
        visits,
        defaultPeriod
    })
);