import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import NewTable from '../../components/NewInfoBox/NewTable';
import { connect } from 'react-redux';
import ConfirmPopup from '../../components/Popups/ConfirmAlert/ConfirmPopup';
import MemberResources from './memberResources';
import selectors from './membersListSelectors';
import InfoBox from '../../components/InfoBox/InfoBox';
import moment from 'moment';
import { SLASHED_US_DATE_FORMAT } from '../../constants/formats';
import { WarningGray } from '../../components/Icon/Icons';
import FacilitySearchBox from './FacilitySearchBox/FacilitySearchBox';
import { useLocation } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import {
    getMembersList,
    updateSelectedMembers,
    resetMemberToDeactivate,
    deactivateMember
} from './membersListActions';
import { PAGE_SIZE } from '../../constants/table';
import { getFacility } from '../Facilities/facilityActions';
import './_member-list.scss';
import SearchBox from '../../components/SearchBox/SearchBox';
import WarningCell from '../../components/NewInfoBox/WarningCell';
import TooltipCell from '../../components/NewInfoBox/TooltipCell';
import MembersStatuses from '../../enums/memberStatuses';
import { setIsMemberTransferPopupOpen } from '../Members/MemberTransfer/MemberTransferActions';
import { MuiSplitButton } from '../../components/controls/MuiSplitButton';
import MemberTransferPopup from '../Members/MemberTransfer/MemberTransferPopup';
import ReplaceMemberActionsCell from './membersActionsCell';

function MembersList({
    members,
    getMembersList,
    isLoading,
    getFacility,
    facility,
    membersTotalCount,
    updateSelectedMembers,
    selectedMembers,
    setIsMemberTransferPopupOpen,
    history,
    isConfirmationPopupVisible,
    memberToDeactivate,
    deactivationConfirmationPopupMessage,
    isAdmin,
    resetMemberToDeactivate,
    deactivateMember
}) {
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const defaultFacilityID = queryParams.get('facilityID');
    const [selectedFacilityID, setSelectedFacilityID] = useState(defaultFacilityID ? parseInt(defaultFacilityID) : null);
    const [filter, setFilter] = useState(null);
    const [filteredSelectedMembers, setFilteredSelectedMembers] = useState([]);
    const options = [{ value: 'Transfer', disabled: false, selected: false, action: () => setIsMemberTransferPopupOpen(true) }];
    const tableRef = useRef(null);
    useEffect(() => {
        if (defaultFacilityID) {
            getFacility(defaultFacilityID);
        }
    }, []);
    useEffect(() => {
        tableRef.current.refreshTable();
    }, [selectedFacilityID, filter]);
    useEffect(() => {
        setFilteredSelectedMembers(selectedMembers.toJS().filter((x) => {
            let filterLowercased;
            if (filter) {
                filterLowercased = filter.toLowerCase();
            }
            return ((!selectedFacilityID || x.facilityID === selectedFacilityID)
                && (!filterLowercased
                    || (x.facilityMemberID && x.facilityMemberID.toLowerCase().includes(filterLowercased))
                    || (x.memberFirstName && x.memberFirstName.toLowerCase().includes(filterLowercased))
                    || (x.memberLastName && x.memberLastName.toLowerCase().includes(filterLowercased))
                    || (`${x.memberFirstName} ${x.memberLastName}`.toLowerCase().includes(filterLowercased))
                    || (`${x.memberLastName} ${x.memberFirstName}`.toLowerCase().includes(filterLowercased))));
        }));
    }, [selectedMembers, selectedFacilityID, filter]);
    const columns = useMemo(
        () => [
            {
                id: MemberResources.accessorMemberStatus,
                Header: <WarningGray />,
                Cell: WarningCell,
                canSort: true,
                sticky: 'left',
                width: 42,
                maxWidth: 42,
                left: 45,
                accessor: MemberResources.accessorStatus,
                sortDescFirst: true,
                getProps: (row) => {
                    return ({
                        isInvalid: row.original.memberStatus === MembersStatuses.invalid,
                        isPending: row.original.memberStatus === MembersStatuses.pending,
                        invalidMessage: row.original.invalidMessage
                    });
                }
            },
            {
                Header: MemberResources.labelFacilityMemberID,
                sticky: 'left',
                accessor: MemberResources.accessorFacilityMemberID,
                minWidth: 128,
                width: 128,
                left: 87
            },
            {
                id: MemberResources.accessorEmployeeName,
                Header: MemberResources.labelEmployeeName,
                sticky: 'left',
                accessor: (x) => {
                    return x.memberFirstName ? `${x.memberFirstName} ${x.memberLastName}` : '';
                },
                minWidth: 200,
                width: 200,
                left: 219,
                isSorted: true,
                isSortedDesc: false
            },
            {
                Header: MemberResources.labelDateOfBirth,
                accessor: MemberResources.accessorMemberDateOfBirth,
                minWidth: 140,
                width: 140,
                maxWidth: 160,
                Cell: cell => (cell.value !== null ? moment(cell.value).format(SLASHED_US_DATE_FORMAT) : null)
            },
            {
                Header: MemberResources.labelPlans,
                accessor: MemberResources.accessorPlanName,
                width: 200,
                maxWidth: 400,
                disableSortBy: true,
                Cell: TooltipCell
            },
            {
                Header: MemberResources.labelFacilityName,
                accessor: MemberResources.accessorFacilityName,
                width: 128,
                maxWidth: 224,
                Cell: TooltipCell
            },
            {
                Header: MemberResources.labelFacilityID,
                accessor: MemberResources.accessorFacilityIDNumber,
                minWidth: 102,
                width: 102
            },
            {
                Header: MemberResources.labelActions,
                id: 'actions',
                accessor: (row) => { return { row, isAdmin }; },
                Cell: ReplaceMemberActionsCell,
                minWidth: 85,
                width: 85,
                sticky: 'right',
                right: 0,
                disableSortBy: true
            }
        ],
        [MemberResources, isAdmin]
    );
    const deactivateHandler = () => {
        resetMemberToDeactivate();
        deactivateMember(memberToDeactivate.memberID).then(() => {
            tableRef.current.refreshTable();
        });
    };
    const updateHandler = (pageNumber, sortColumn, sortOrder) => {
        getMembersList(pageNumber, PAGE_SIZE, selectedFacilityID, sortColumn, sortOrder, filter);
    };

    const changeFacilityHandler = (value) => {
        const facilityID = value ? value.id : null;
        setSelectedFacilityID(facilityID);
    };

    const onFilterChange = (value) => {
        setFilter(value);
    };
    const getMemberId = (item) => {
        return item.memberID;
    };
    return (
        <div className="member-list">
            <MemberTransferPopup history={history} />
            <h2 className="member-list__title">{MemberResources.title}</h2>
            <div className="member-list__filters-panel">
                <SearchBox className="member-list__members-search-bar" placeholder={MemberResources.placeholderMemberSearch}
                    onChange={onFilterChange}
                />
                <FacilitySearchBox className="member-list__facility-search-box" onChange={changeFacilityHandler}
                    facility={facility}
                    inputLabel={MemberResources.facilitySearchBoxInputLabel}
                />
                <div className="member-list__bulk_options">
                    <MuiSplitButton id="memberBulkOptionButton"
                        label={{ value: MemberResources.bulkOptionsButtonText, description: 'Dropdown button' }}
                        classNames={['upper', 'no-transition', 'btn_new']}
                        options={options} disabled={filteredSelectedMembers.length === 0}
                    />
                </div>
            </div>
            <InfoBox>
                {isLoading ? <Spinner /> : null}
                <ConfirmPopup showPopup={isConfirmationPopupVisible} body={<div className="message">{deactivationConfirmationPopupMessage}</div>}
                    onConfirm={deactivateHandler}
                    onCancel={resetMemberToDeactivate}
                    labelOk={MemberResources.buttonDeactivate}
                />
                <NewTable columns={columns} initialData={members}
                    update={updateHandler}
                    initialSortBy={{
                        id: MemberResources.defaultSortColumn,
                        desc: false
                    }}
                    totalCount={membersTotalCount}
                    updateSelectedItems={updateSelectedMembers}
                    selectedItems={filteredSelectedMembers}
                    getItemId={getMemberId}
                    errorRowCondition={row => row.original.memberStatus === MembersStatuses.invalid}
                    warningRowCondition={row => row.original.memberStatus === MembersStatuses.pending}
                    textNoDataFound={MemberResources.textNoMemberFound}
                    requireSelect
                    ref={tableRef}
                />
            </InfoBox>
        </div>
    );
}
MembersList.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    facility: PropTypes.object,
    membersTotalCount: PropTypes.number,
    members: PropTypes.object.isRequired,
    getMembersList: PropTypes.func.isRequired,
    selectedMembers: PropTypes.object.isRequired,
    getFacility: PropTypes.func.isRequired,
    updateSelectedMembers: PropTypes.func.isRequired,
    setIsMemberTransferPopupOpen: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    memberToDeactivate: PropTypes.object,
    deactivationConfirmationPopupMessage: PropTypes.string,
    isConfirmationPopupVisible: PropTypes.bool.isRequired,
    resetMemberToDeactivate: PropTypes.func.isRequired,
    deactivateMember: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    getMembersList,
    getFacility,
    updateSelectedMembers,
    setIsMemberTransferPopupOpen,
    resetMemberToDeactivate,
    deactivateMember
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(MembersList);
